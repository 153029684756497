import { faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import TableTree from '../../../components/TableTree/TableTree';
import apiCall from '../../../helpers/apiCall';
import GroupForm from './GroupForm';

class GroupsList extends Component {
    state = {
        toDelete: null,
        modalConfirmAction: null,
        group: '',
        message: '',
        groupForUser: {},
        userForGroup: null,
        userOptions: [],
    };

    unsetUserToDelete = () => {
        this.setState({
            toDelete: false,
        });
    };

    toggleShowAddUserFormModal = () => {
        this.setState({
            showAddUserFormModal: !this.state.showAddUserFormModal,
        });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();
        const { success, message } = await apiCall('POST', `/users/groups`, {
            users: this.state.userForGroup.map((selected) => selected.value),
            groups: [this.state.groupForUser._id],
        });

        if (success) {
            this.props.setGlobalAlert({
                type: 'success',
                message: 'Users added to group',
            });
            this.toggleShowAddUserFormModal();
        } else {
            this.setState({
                message: {
                    text: message,
                    type: 'warning',
                },
            });
        }
    };

    loadMemberOptions = async () => {
        const { response, success } = await apiCall('GET', '/users');

        if (success) {
            this.setState({
                userOptions: response.docs.map(
                    ({ _id: value, firstName, lastName }) => ({
                        value,
                        name: `${firstName} ${lastName}`,
                    })
                ),
            });
        }
    };

    render() {
        const { message } = this.state;
        return (
            <div>
                <TableTree
                    apiCall={{
                        method: 'GET',
                        path: '/groups',
                    }}
                    pluralWord='groups'
                    singularWord='group'
                    itemForm={GroupForm}
                    rowButtons={[
                        {
                            text: 'Add new user to group',
                            icon: faUserPlus,
                            clickCallback: (e, row, reloadTable) => {
                                this.setState({
                                    showAddUserFormModal:
                                        !this.state.showAddUserFormModal,
                                    groupForUser: {
                                        _id: row._id,
                                        name: row.name,
                                    },
                                });
                            },
                        },
                        {
                            text: 'Delete user to group',
                            icon: faTrash,
                            variant: 'danger',
                            clickCallback: (e, row, reloadTable) => {
                                this.setState({
                                    toDelete: row,
                                    modalConfirmAction: async () => {
                                        this.setState({
                                            toDelete: null,
                                        });

                                        const { success, message } =
                                            await apiCall(
                                                'DELETE',
                                                '/groups/' + row._id
                                            );

                                        if (success) {
                                            this.props.setGlobalAlert({
                                                type: 'success',
                                                message: 'Group deleted!',
                                            });
                                            await reloadTable();
                                        } else {
                                            this.props.setGlobalAlert({
                                                type: 'error',
                                                message,
                                            });
                                        }
                                    },
                                });
                            },
                        },
                    ]}
                />
                <Modal
                    show={this.state.showAddUserFormModal}
                    onHide={this.toggleShowAddUserFormModal}
                    onShow={this.loadMemberOptions}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add new user to {this.state.groupForUser.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            className={
                                'form' + (message ? ' form--with-error' : '')
                            }>
                            {message && (
                                <Alert variant={message.type}>
                                    {message.text}
                                </Alert>
                            )}
                            <div className='form__content'>
                                <form
                                    action='/'
                                    onSubmit={this.handleFormSubmit}>
                                    <div
                                        className={`form__field${
                                            this.state.noParent
                                                ? ' disabled'
                                                : ''
                                        }`}>
                                        <label htmlFor='parent'>User</label>
                                        <AsyncSelect
                                            defaultOptions
                                            isMulti
                                            loadOptions={async (inputValue) => {
                                                const { success, response } =
                                                    await apiCall(
                                                        'GET',
                                                        `/users?excludeGroup=${this.state.groupForUser._id}` +
                                                            (inputValue
                                                                ? `&search=${inputValue}`
                                                                : '')
                                                    );

                                                if (success) {
                                                    return response.docs.map(
                                                        ({
                                                            _id: value,
                                                            firstName,
                                                            lastName,
                                                        }) => ({
                                                            value,
                                                            label: `${firstName} ${lastName}`,
                                                        })
                                                    );
                                                }
                                            }}
                                            placeholder='Select a user to add to the group'
                                            onChange={(userForGroup) => {
                                                this.setState({
                                                    userForGroup,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='form__buttons'>
                                        <Button
                                            variant='primary'
                                            onClick={this.handleFormSubmit}>
                                            Add
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <ConfirmationModal
                    show={this.state.toDelete}
                    hideModal={this.unsetUserToDelete}
                    titleText={`Delete group ${
                        this.state.toDelete ? this.state.toDelete.name : ''
                    } `}
                    bodyText='Are you sure you want to delete the group?'
                    confirmAction={this.state.modalConfirmAction}
                />
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
})(GroupsList);
