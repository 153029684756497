import {
    faCloudDownloadAlt,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

class DownloadButton extends Component {
    state = {
        dialogOpen: false,
        loading: false,
        fileType: 'XLSX',
    };

    downloadUrl = new URLSearchParams();

    componentDidMount() {
        this.downloadUrl.set('dateEnd', this.props.dateEnd);
        this.downloadUrl.set('dateStart', this.props.dateStart);
        this.downloadUrl.set('fileType', this.state.fileType);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dateEnd !== this.props.dateEnd) {
            this.downloadUrl.set('dateEnd', this.props.dateEnd);
        }

        if (prevProps.dateStart !== this.props.dateStart) {
            this.downloadUrl.set('dateStart', this.props.dateStart);
        }
    }

    handleClose = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            change: true,
            skip: 0,
        });

        this.downloadUrl.set(e.target.name, e.target.value);
    };

    openDialog = () => {
        this.setState({
            dialogOpen: true,
        });
    };

    handleDownload = async () => {
        const token = localStorage.getItem('authToken');

        if (token) {
            this.setState({
                loading: true,
            });
            const headers = new Headers(),
                anchor = document.createElement('a');
            headers.append('Authorization', 'Bearer ' + token);
            const URL = window.URL.createObjectURL(
                await (
                    await fetch(
                        process.env.REACT_APP_API_URL +
                            `/devices/${this.props.match.params.deviceId}/logs/download` +
                            (this.downloadUrl.toString()
                                ? `?${this.downloadUrl.toString()}`
                                : ''),
                        { headers }
                    )
                ).blob()
            );

            anchor.href = URL;
            anchor.download = `${this.props.match.params.deviceId}_${
                this.state.dateStart
            }_${this.state.dateEnd}.${this.state.fileType.toLowerCase()}`;
            anchor.click();

            window.URL.revokeObjectURL(URL);

            this.setState({
                loading: false,
                dialogOpen: false,
            });
        }
    };

    render() {
        const { loading } = this.state;

        return (
            <div className='ml-auto'>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={this.openDialog}>
                    <Fa icon={faCloudDownloadAlt} /> &nbsp; Download logs
                </Button>
                <Dialog
                    open={this.state.dialogOpen}
                    maxWidth='xs'
                    onClose={() => {}}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>
                        Download logs
                    </DialogTitle>
                    {loading ? (
                        <DialogContent>
                            <DialogContentText>Creating file</DialogContentText>
                        </DialogContent>
                    ) : (
                        <DialogContent>
                            <DialogContentText>
                                Select a date range from which you would like to
                                download logs from
                            </DialogContentText>
                            <div className='flex flex-col'>
                                <FormControl className='mt-4'>
                                    <InputLabel id='file-type'>
                                        file format
                                    </InputLabel>
                                    <Select
                                        labelId='file-type'
                                        value={this.state.fileType}
                                        onChange={(s, v) => {
                                            this.setState(
                                                {
                                                    fileType: v.props.value,
                                                },
                                                () => {
                                                    this.downloadUrl.set(
                                                        'fileType',
                                                        v.props.value
                                                    );
                                                }
                                            );
                                        }}>
                                        <MenuItem value='XLSX'>.xlsx</MenuItem>
                                        <MenuItem value='CSV'>.csv</MenuItem>
                                        <MenuItem value='pdf'>.pdf</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </DialogContent>
                    )}
                    {!loading ? (
                        <DialogActions>
                            <Button
                                onClick={this.handleClose}
                                color='secondary'>
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                onClick={this.handleDownload}
                                color='primary'
                                download>
                                Download
                            </Button>
                        </DialogActions>
                    ) : (
                        <div className='loading-file'>
                            <Fa icon={faSpinner} spin />
                        </div>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default withRouter(DownloadButton);
