import moment from 'moment';
import React, { Component } from 'react';
import DatetimeRangePicker from 'react-datetime-range-picker';

export default class DateRangeFilter extends Component {
    state = {
        focusedInput: null,
        end: this.props.dateEnd,
        start: this.props.dateStart,
    };

    handleDateChange = async ({ end, start }) => {
        this.setState({ end, start });
        await this.props.onClose(start, end);
    };

    render() {
        return (
            <DatetimeRangePicker
                endDate={this.state.end}
                startDate={this.state.start}
                onChange={this.handleDateChange}
                dateFormat='DD/MM/YY'
            />
        );
    }
}
