import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AsyncSelect from 'react-select/async/dist/react-select.esm';

import apiCall from '../../../helpers/apiCall';

class Device extends Component {
    state = {
        _id: null,
        name: '',
        serialNumber: undefined,
        firmware: undefined,
        ICCID: undefined,
        notes: undefined,
        imei: undefined,
        turbidityMultiplier: undefined,
        turbidityOffset: undefined,
        turbidityRange: undefined,
        groups: [],
    };

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            const { success, response, message } = await apiCall(
                'GET',
                `/devices/${this.props.match.params.id}`
            );

            if (success) {
                this.setState({
                    ...response,
                });
            } else {
                this.props.setGlobalAlert({
                    type: 'error',
                    message,
                });
            }
        }
    }

    handleInputChange = (e) => {
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const {
                name,
                serialNumber,
                firmware,
                ICCID,
                notes,
                imei,
                turbidityMultiplier,
                turbidityOffset,
                turbidityRange,
            } = this.state,
            { success, message } = await apiCall(
                this.props.match.params.id !== 'new' ? 'PUT' : 'POST',
                '/devices' +
                    (this.props.match.params.id !== 'new'
                        ? `/${this.props.match.params.id}`
                        : ''),
                {
                    name,
                    serialNumber,
                    firmware,
                    ICCID,
                    notes,
                    imei,
                    turbidityMultiplier,
                    turbidityOffset,
                    turbidityRange,
                    groups:
                        this.state?.groups?.map?.((group) => group.value) ?? [],
                }
            );

        this.props.setGlobalAlert({
            type: success ? 'success' : 'error',
            message,
        });
    };

    render() {
        return (
            <section id='device'>
                <Row>
                    <Col>
                        <span>
                            <b>Reference ID: </b>
                            {this.state._id
                                ?.toUpperCase()
                                .substr(0, 16)
                                .replace(
                                    /([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})/,
                                    '$1-$2-$3-$4'
                                )}
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            <b>Device ID: </b>
                            {this.state._id
                                ?.toUpperCase()
                                .substr(-8)
                                .replace(
                                    /([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})/,
                                    '$1-$2-$3-$4'
                                )}
                        </span>
                    </Col>
                </Row>
                <hr />
                <div className='form'>
                    <div className='form__content'>
                        <form action='/' onSubmit={this.handleFormSubmit}>
                            <Row>
                                <Col>
                                    <div className='form__field'>
                                        <label htmlFor='name'>Name</label>
                                        <input
                                            type='text'
                                            name='name'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state?.name}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='serialNumber'>
                                            Serial number
                                        </label>
                                        <input
                                            type='text'
                                            name='serialNumber'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state?.serialNumber
                                            }
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='imei'>IMEI</label>
                                        <input
                                            type='text'
                                            name='imei'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state?.imei}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='turbidityMultiplier'>
                                            Turbidity multiplier
                                        </label>
                                        <input
                                            type='number'
                                            step='any'
                                            name='turbidityMultiplier'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state?.turbidityMultiplier
                                            }
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='form__field'>
                                        <label htmlFor='firmware'>
                                            Firmware
                                        </label>
                                        <input
                                            type='text'
                                            name='firmware'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state?.firmware}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='ICCID'>ICCID</label>
                                        <input
                                            type='text'
                                            name='ICCID'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state?.ICCID}
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='turbidityOffset'>
                                            Turbidity offset
                                        </label>
                                        <input
                                            type='number'
                                            name='turbidityOffset'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state?.turbidityOffset
                                            }
                                        />
                                    </div>
                                    <div className='form__field'>
                                        <label htmlFor='turbidityRange'>
                                            Turbidity range
                                        </label>
                                        <input
                                            type='text'
                                            name='turbidityRange'
                                            onChange={this.handleInputChange}
                                            defaultValue={
                                                this.state?.turbidityRange
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <div className='form__field'>
                                        <label htmlFor='groups'>Groups</label>
                                        <AsyncSelect
                                            defaultOptions
                                            value={this.state.groups}
                                            isMulti
                                            label='groups'
                                            loadOptions={async (inputValue) => {
                                                const { success, response } =
                                                    await apiCall(
                                                        'GET',
                                                        '/groups' +
                                                            (inputValue
                                                                ? `?search=${inputValue}`
                                                                : '')
                                                    );

                                                if (success) {
                                                    return response.docs.map(
                                                        ({
                                                            _id: value,
                                                            name: label,
                                                        }) => ({
                                                            value,
                                                            label,
                                                        })
                                                    );
                                                }
                                            }}
                                            placeholder='Select a user to add to the group'
                                            onChange={(groups) => {
                                                this.setState({
                                                    groups,
                                                });
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div />
                                    <div className='form__field'>
                                        <label htmlFor='notes'>
                                            Admin notes
                                        </label>
                                        <textarea
                                            name='notes'
                                            onChange={this.handleInputChange}
                                            defaultValue={this.state.notes}
                                            rows='6'
                                            style={{
                                                minHeight: '6rem',
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className='form__buttons'>
                                <Button type='submit' variant='primary'>
                                    {this.props.match.params.id
                                        ? 'Save'
                                        : 'Create'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
})(withRouter(Device));
