import './Graph.scss';

import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import apiCall from '../../../helpers/apiCall';
import round from '../../../helpers/round';
import DateRangeFilter from './DateRangeFilter';
import DownloadButton from './DownloadButton';

// TODO - improve to not make unnecessary calls to the API
class Graph extends Component {
    state = {
        loading: true,
        chartData: [],
        fieldsToSelect: ['flow', 'pressure', 'turbidity', 'battery', 'signal'],
        dateStart: moment().add({ days: -30 }),
        dateEnd: moment(),
        skip: 0,
        change: true,
        firstRender: true,
        message: null,
        totalVolume: 0,
        averageFlow: 0,
        averagePressure: 0,
        averageTurbidity: 0,
        maxFlow: 0,
        minFlow: 0,
        maxPressure: 0,
        minPressure: 0,
        maxTurbidity: 0,
        name: '',
        minTurbidity: 0,
        firmwareVersion: 0,
    };

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        if (this.state.change) {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                });
            }

            let query = new URLSearchParams();

            query.set('fieldsToSelect', 'flow,pressure,turbidity');
            query.set('skip', this.state.skip);

            if (this.state.dateStart) {
                query.set('dateStart', this.state.dateStart.toISOString());
            }

            if (this.state.dateEnd) {
                query.set('dateEnd', this.state.dateEnd.toISOString());
            }

            const { success, response, message } = await apiCall(
                    'GET',
                    '/devices/' +
                        this.props.deviceId +
                        '/logs?' +
                        query.toString()
                ),
                { fieldsToSelect } = this.state;
            let chartData = [['Timestamp']];

            chartData[0].push(
                ...fieldsToSelect.map(
                    (v) => v.charAt(0).toUpperCase() + v.slice(1)
                )
            );

            if (success) {
                console.log(response);
                let totalVolume,
                    averageFlow,
                    averagePressure,
                    averageTurbidity,
                    maxFlow,
                    minFlow,
                    maxPressure,
                    minPressure,
                    maxTurbidity,
                    name,
                    minTurbidity,
                    totalLogCount,
                    battery,
                    firmwareVersion,
                    signal;
                if (!response.logs) {
                    chartData = null;
                } else {
                    ({
                        totalVolume,
                        averageFlow,
                        averagePressure,
                        averageTurbidity,
                        maxFlow,
                        minFlow,
                        maxPressure,
                        minPressure,
                        maxTurbidity,
                        minTurbidity,
                        name,
                        totalLogCount,
                        battery,
                        signal,
                        firmwareVersion,
                    } = response);

                    for (const row of response.logs) {
                        const arr = [new Date(row.timestamp)];

                        for (const field of fieldsToSelect) {
                            arr.push(row[field]);
                        }

                        chartData.push(arr);
                    }
                }

                this.setState({
                    chartData,
                    message:
                        chartData === null
                            ? 'No data found for the selected filters'
                            : null,
                    loading: false,
                    change: false,
                    totalVolume,
                    averageFlow,
                    averagePressure,
                    averageTurbidity,
                    maxFlow,
                    minFlow,
                    maxPressure,
                    name,
                    minPressure,
                    maxTurbidity,
                    minTurbidity,
                    totalLogCount,
                    firmwareVersion,
                });
            } else {
                this.props.setGlobalAlert({
                    type: 'error',
                    message,
                });
            }
        }
    };

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            change: true,
            skip: 0,
        });
    };

    handleNextDataSet = () => {
        this.setState(
            {
                skip: this.state.skip < 1000 ? 0 : this.state.skip - 1000,
                change: true,
            },
            this.loadData
        );
    };

    handlePreviousDataSet = () => {
        this.setState(
            {
                skip: this.state.skip + 1000,
                change: true,
            },
            this.loadData
        );
    };

    handleDateChange = (dateStart, dateEnd) => {
        const { dateStart: currentStart, dateEnd: currentEnd } = this.state;

        if (dateStart !== currentStart || dateEnd !== currentEnd) {
            this.setState(
                {
                    dateStart,
                    dateEnd,
                    change: true,
                    skip: 0,
                },
                this.loadData
            );
        }
    };

    render() {
        const {
            totalVolume,
            averageFlow,
            averagePressure,
            averageTurbidity,
            maxFlow,
            minFlow,
            maxPressure,
            minPressure,
            maxTurbidity,
            minTurbidity,
            skip,
            name,
            dateEnd,
            dateStart,
            loading,
            totalLogCount,
            chartData,
            message,
            firmwareVersion,
        } = this.state;

        return (
            <div className='log-graph'>
                <h2>{name}</h2>
                <div>
                    <div className='graph-controls flex p-2'>
                        <DateRangeFilter
                            onClose={this.handleDateChange}
                            dateEnd={dateEnd}
                            dateStart={dateStart}
                        />
                        <DownloadButton
                            dateEnd={dateEnd}
                            dateStart={dateStart}
                        />
                        <div className='mx-3 border-r border-solid self-center border-gray-400 h-7'></div>
                        <Tooltip
                            title='Show previous set of data'
                            placement='top'>
                            <div>
                                <Button
                                    variant='outlined'
                                    disabled={skip + 1000 > totalLogCount}
                                    onClick={this.handlePreviousDataSet}>
                                    <Fa icon={faChevronLeft} />
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title='Show next set of data' placement='top'>
                            <div>
                                <Button
                                    className='ml-2'
                                    variant='outlined'
                                    disabled={skip === 0}
                                    onClick={this.handleNextDataSet}>
                                    <Fa icon={faChevronRight} />
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                    {message ? (
                        <div className='p-4 text-center font-weight-bold text-xl'>
                            {message}
                        </div>
                    ) : loading ? (
                        <div className='center-loading big'>
                            <Spinner animation='border' />
                        </div>
                    ) : (
                        <Chart
                            width='100%'
                            height='600px'
                            chartType='LineChart'
                            type='number'
                            options={{
                                chartArea: {
                                    left: 100,
                                    right: 15,
                                    top: 32,
                                    bottom: 100,
                                },
                                legend: {
                                    position: 'top',
                                },
                                explorer: {
                                    keepInBounds: true,
                                    actions: [
                                        'dragToZoom',
                                        'rightClickToReset',
                                    ],
                                    axis: 'horizontal',
                                    maxZoomIn: 0.05,
                                },
                                vAxis: {
                                    scaleType: 'linear',
                                    textStyle: {
                                        fontSize: 11,
                                    },
                                },
                                hAxis: {
                                    slantedText: true,
                                    slantedTextAngle: 45,
                                    textStyle: {
                                        fontSize: 11,
                                    },
                                    format: 'dd/MM/YYYY hh:mm:ss a',
                                },
                            }}
                            data={chartData}
                            legendToggle
                        />
                    )}
                </div>
                <div className='mt-4'>
                    <div className='mr-4 inline-block'>
                        <b>Total volume: </b>
                        {totalVolume}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Average flow: </b>
                        {round(averageFlow)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Average pressure: </b>
                        {round(averagePressure)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Average turbidity: </b>
                        {round(averageTurbidity)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Latest Firmware Version: </b>
                        {firmwareVersion}
                    </div>
                </div>
                <div className='mt-1'>
                    <div className='mr-4 inline-block'>
                        <b>Min flow: </b>
                        {round(minFlow)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Max flow: </b>
                        {round(maxFlow)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Min pressure: </b>
                        {round(minPressure)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Max pressure: </b>
                        {round(maxPressure)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Min turbidity: </b>
                        {round(minTurbidity)}
                    </div>
                    <div className='mr-4 inline-block'>
                        <b>Max turbidity: </b>
                        {round(maxTurbidity)}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(Graph));
