import './User.scss';

import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import apiCall from '../../../helpers/apiCall';
import UserActions from './UserActions';
import UserForm from './UserForm';

class User extends Component {
    state = {
        user: null,
        modal: null,
    };

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            const { success, response, message } = await apiCall(
                'GET',
                '/users/' + this.props.match.params.id
            );

            if (success) {
                this.setState({
                    user: response,
                });

                this.props.pushBreadcrumbLink({
                    text: `${response.firstName} ${response.lastName}`,
                    path: '/admin/users/' + this.props.match.params.id,
                });
            } else {
                this.props.setGlobalAlert({ type: 'error', message });
            }
        } else {
            this.props.pushBreadcrumbLink({
                text: 'Create new user',
                path: '/admin/users/new',
            });
        }
    }

    componentWillUnmount() {
        if (this.props.match.params.id !== 'new') {
            this.props.removeBreadcrumbLink({
                text: `${this.state.user.firstName} ${this.state.user.lastName}`,
                path: '/admin/users/' + this.props.match.params.id,
            });
        } else {
            this.props.removeBreadcrumbLink({
                text: 'Create new user',
                path: '/admin/users/new',
            });
        }
    }

    setUserModal = (modal) => {
        this.setState({
            modal,
        });
    };

    render() {
        return this.props.match.params.id === 'new' ? (
            <UserForm />
        ) : this.state.user ? (
            <div id='user-page'>
                <div id='user-id'>
                    <Row>
                        <Col>
                            <h3>
                                {this.state.user.firstName}
                                &nbsp;
                                {this.state.user.lastName}
                            </h3>
                        </Col>
                        <Col
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                            <UserActions
                                verified={!!this.state.user.verified}
                                suspended={!!this.state.user.suspended}
                                isAdmin={!!this.state.user.adminLevel}
                                userId={this.state.user._id}
                                setUserModal={this.setUserModal}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span>
                                <b>Account ID: </b>
                                {this.state.user._id
                                    .toLocaleUpperCase()
                                    .substr(0, 16)
                                    .replace(
                                        /([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})/,
                                        '$1-$2-$3-$4'
                                    )}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>
                                <b>User ID: </b>
                                {this.state.user._id
                                    .toLocaleUpperCase()
                                    .substr(-8)
                                    .replace(
                                        /([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})/,
                                        '$1-$2-$3-$4'
                                    )}
                            </span>
                        </Col>
                    </Row>
                    <hr />
                </div>
                <UserForm user={this.state.user} />
                {this.state.modal}
            </div>
        ) : (
            <div />
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
})(withRouter(User));
