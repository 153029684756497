import { faEye } from '@fortawesome/free-regular-svg-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ApiTable } from '../../components/ApiTable';
import { Graph } from './Graph';

class Logs extends Component {
    state = {
        logs: [],
    };

    render() {
        let path = `/users/${this.props.loggedIn.user._id}/devices`,
            groupFilters = false;

        if (this.props.loggedIn.user.adminLevel) {
            path = `/devices`;
            groupFilters = true;
        }

        return (
            <div className='home p-4'>
                {this.props.match.params.deviceId ? (
                    <Graph deviceId={this.props.match.params.deviceId} />
                ) : (
                    <ApiTable
                        apiCall={{
                            method: 'GET',
                            path: `${path}`,
                        }}
                        groupFilters={groupFilters}
                        admin={this.props.loggedIn.user.adminLevel}
                        columns={[
                            {
                                text: 'ID',
                                field: '_id',
                            },
                            {
                                text: 'Name',
                                field: 'name',
                                sortKey: 'name',
                            },
                            {
                                text: 'IMEI',
                                field: 'imei',
                                sortKey: 'imei',
                            },
                            {
                                text: 'Serial Number',
                                field: 'serialNumber',
                                sortKey: 'serialNumber',
                            },
                        ]}
                        rowButtons={[
                            {
                                text: 'View device logs',
                                url: '/logs/:_id',
                                icon: faEye,
                            },
                        ]}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    (state) => ({
        loggedIn: state.loggedIn,
    }),
    {
        setGlobalAlert: (payload) => ({
            type: 'SET_GLOBAL_ALERT',
            payload,
        }),
    }
)(withRouter(Logs));
